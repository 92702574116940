import React, { useState, useEffect, useCallback } from "react";
import { Transition } from "@headlessui/react";
import * as yup from "yup";
import { ValidationError } from "yup";
import PlacesAutocomplete from "./PlacesAutocomplete";
import TextInput from "./TextInput";
import RegionDropdown from "./RegionDropdown";
import { IShippingAddress } from "../types/types";
import { debounce } from "../utils/debounce";
import config from "../utils/config";

interface IAddressInputProps {
  value: Partial<IShippingAddress> | null;
  onChange: (value: IShippingAddress | null) => void;
  hideOptIn?: boolean;
  disableCountry?: boolean;
  errors?: Record<string, string[]>;
}

const addressSchema = yup.object().shape({
  firstName: yup.string().required('Please enter your first name'),
  lastName: yup.string().required('Please enter your last name'),
  address1: yup.string().required('Please enter your address'),
  address2: yup.string().nullable(),
  city: yup.string().required('Please enter your city'),
  province: yup.string().required('Please select your region'),
  country: yup.string().required('Please enter your country'),
  zip: yup.string().required('Please enter your country'),
  phone: yup.string().nullable(),
});

const AddressInput: React.FC<IAddressInputProps> = ({ value, onChange, hideOptIn = false, disableCountry = false, errors = {} }) => {
  const [address, setAddress] = useState<Partial<IShippingAddress> | null>(value);
  const [showFullAddressForm, setShowFullAddressForm] = useState(false);
  const [validationErrors, setValidationErrors] = useState<Record<string, string[]>>({});
  const [isAutocompleteAvailable, setIsAutocompleteAvailable] = useState(true);

  const validateForm = useCallback(() => {
    addressSchema.validate(address, { abortEarly: false })
      .then(() => setValidationErrors({}))
      .catch((validationError: ValidationError) => {
        const formErrors: Record<string, string[]> = {};
        if (validationError.inner) {
          validationError.inner.forEach((error) => {
            if (error.path) {
              formErrors[error.path] = [error.message];
            }
          });
        }
        setValidationErrors(formErrors);
      });
  }, [address]);

  useEffect(() => {
    if (value) {
      setShowFullAddressForm(true);
    }
  }, [value]);

  useEffect(() => {
    if (address && isValidAddress(address)) {
      onChange(address);
    }
  }, [address, onChange]);

  useEffect(() => {
    if ('shippingAddress' in errors) {
      validateForm();
    }
  }, [address, errors, validateForm]);

  useEffect(() => {
    // Check if the PlacesAutocomplete service is available
    const checkAutocompleteAvailability = async () => {
      try {
        const isAvailable = await PlacesAutocomplete.checkAvailability();
        setIsAutocompleteAvailable(isAvailable);
      } catch (error) {
        console.error('Error checking Places API availability:', error);
        setIsAutocompleteAvailable(false);
      }
    };

    checkAutocompleteAvailability();
  }, []);

  const isValidAddress = (addressData: any): addressData is IShippingAddress => {
    try {
      addressSchema.validateSync(addressData, { abortEarly: false });
      return true;
    } catch (e) {
      return false;
    }
  };

  const debouncedValidateForm = debounce(validateForm, 300);

  const validateField = async (name: string, value: string) => {
    try {
      await addressSchema.validateAt(name, { [name]: value, country: config.countryCode });
      setValidationErrors(prev => {
        const newErrors = { ...prev };
        delete newErrors[name];
        return newErrors;
      });
    } catch (error) {
      if (error instanceof yup.ValidationError) {
        setValidationErrors(prev => ({
          ...prev,
          [name]: [error.message]
        }));
      }
    }
  };

  const handleAddressChange = (event: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    const { name, value } = event.target;
    const updatedAddress = { ...address, [name]: value };
    setAddress(updatedAddress);
    validateField(name, value);
    debouncedValidateForm();
  };

  const handleCountryChange = (countryCode: string) => {
    if (disableCountry) {
      return;
    }
    // Get the current path and search params
    const { pathname, search } = window.location;

    // Map country codes to corresponding domains
    const domainMap: { [key in 'NZ' | 'AU' | 'US' | 'UK']: string } = {
      NZ: 'checkout.bushbuck.co.nz',
      AU: 'checkout.bushbuck.com.au',
      US: 'checkout.bushbuck.com',
      UK: 'checkout.bushbuck.co.uk',
    };

    const newDomain = domainMap[countryCode as 'NZ' | 'AU' | 'US' | 'UK'];
    if (newDomain) {
      window.location.href = `https://${newDomain}${pathname}${search}`;
    } else {
      console.error('Unsupported country code');
    }
  };

  const handleAddressAutocomplete = (value: Partial<IShippingAddress> | null) => {
    setValidationErrors({});
    if (!value) {
      setAddress({...address,
        address1: '',
        address2: null,
        city: '',
        province: '',
        zip: '',
      });
      onChange(null);
    } else {
      setAddress({...address, ...value});
      setShowFullAddressForm(true);
    }
  };

  return (
    <fieldset>
      <div className="relative flex gap-3 mt-2">
        <div className="w-full">
          <TextInput
            type="text"
            name="firstName"
            label="First name"
            autoComplete="given-name"
            placeholder="First name"
            value={address?.firstName ?? ''}
            errors={validationErrors}
            onChange={handleAddressChange}
          />
        </div>
        <div className="w-full">
          <TextInput
            type="text"
            name="lastName"
            label="Last name"
            autoComplete="family-name"
            placeholder="Last name"
            value={address?.lastName ?? ''}
            errors={validationErrors}
            onChange={handleAddressChange}
          />
        </div>
      </div>
      <div className="mt-2">
        <div>
          {isAutocompleteAvailable ? (
            <PlacesAutocomplete
              name="address"
              initialValue={address?.address1 ?? ''}
              onChange={handleAddressAutocomplete}
            />
          ) : (
            <TextInput
              type="text"
              name="address1"
              label="Address"
              autoComplete="address-line1"
              placeholder="Address"
              onChange={handleAddressChange}
              value={address?.address1 ?? ''}
              errors={validationErrors}
            />
          )}
        </div>
        <div className="overflow-hidden">
          <Transition
            show={showFullAddressForm}
            enter="transition ease duration-500 transform"
            enterFrom="opacity-0 -translate-y-12"
            enterTo="opacity-100 translate-y-0"
            leave="transition ease duration-300 transform"
            leaveFrom="opacity-100 translate-y-0"
            leaveTo="opacity-0 -translate-y-12"
          >
            <div>
              <TextInput
                type="text"
                name="address2"
                label="Apartment, suite, etc."
                autoComplete="address-line2"
                placeholder="Apartment, suite, etc."
                hideLabel={true}
                onChange={handleAddressChange}
                value={address?.address2 ?? ''}
                errors={validationErrors}
              />
              <div className="flex gap-3 items-start">
                <div className="flex-col w-full">
                  <TextInput
                    type="text"
                    name="city"
                    label="City"
                    hideLabel={true}
                    autoComplete="address-level2"
                    placeholder="City"
                    onChange={handleAddressChange}
                    value={address?.city ?? ''}
                    errors={validationErrors}
                  />
                </div>
                <div className="flex-col w-full">
                  <RegionDropdown
                    value={address?.province ?? ''}
                    onChange={handleAddressChange}
                    regions={config.shippingRegions}
                    error={validationErrors.province?.[0]}
                  />
                </div>
                <div className="flex-col w-full">
                  <TextInput
                    type="text"
                    name="zip"
                    label="Postcode"
                    hideLabel={true}
                    autoComplete="postal-code"
                    placeholder="Postcode"
                    onChange={handleAddressChange}
                    value={address?.zip ?? ''}
                    errors={validationErrors}
                  />
                </div>
              </div>
            </div>
          </Transition>
        </div>
        <div className="mt-2">
          <label htmlFor="country" className="sr-only block text-sm font-medium text-gray-700">
            Country
          </label>
          <select
            id="country"
            name="country"
            autoComplete="country-name"
            className="relative block p-3 rounded-full w-full border-0 bg-transparent text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10 focus:ring-4 focus:ring-inset focus:ring-gray-300 sm:text-sm sm:leading-6"
            disabled={disableCountry}
            value={config.countryCode}
            defaultValue={config.countryCode}
            onChange={(event) => handleCountryChange(event.target.value)}
          >
            <option value="NZ">New Zealand</option>
            <option value="AU">Australia</option>
            <option value="US">United States</option>
            <option value="UK">United Kingdom</option>
          </select>
        </div>
      </div>
      <div className="mt-2">
        <TextInput
          type="text"
          name="phone"
          label="Phone number"
          autoComplete="tel"
          placeholder="Phone number"
          value={address?.phone ?? ''}
          onChange={handleAddressChange}
          errors={validationErrors}
        />
        {!hideOptIn && (
          <div className="mt-2 relative flex items-start">
            <div className="flex h-6 items-center">
              <input
                id="sms_marketing_opt_in"
                name="sms_marketing_opt_in"
                type="checkbox"
                className="h-4 w-4 rounded border-gray-300 text-gray-600 focus:ring-gray-600"
              />
            </div>
            <div className="ml-3 text-sm leading-6">
              <label htmlFor="sms_marketing_opt_in" className="font-normal text-gray-900">
                Text me with news and offers
              </label>
            </div>
          </div>
        )}
      </div>
    </fieldset>
  );
};

export default AddressInput;
